import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes


  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Sustainability & Filipe" />

    <div className="first-section global-wrapper">
      <h1>Environment</h1>
      <p>The environment has always been a topic of interest of Filipe. On this page, it's listed some of his works under the topic of sustainability.</p>

    <div className="">
        <h2>Articles</h2>
        <p>Most of Filipe's articles were written in Portuguese, as a Young Reporter for the Environment and can be found <a href="https://jra.abae.pt/plataforma/?s=Filipe+Correia">on JRA's website</a>.</p>
        <p>The articles he published on this website can be found here:</p>
        <div>
          <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h3>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h3>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              )
            })}
          </ol>
        </div>
        
        
    </div>
    <div>
        <h2>Podcast EESPS</h2>
        <p>While Filipe was still in high school, he and his friends, Rodrigo Cardoso, Tiago Rocha and Miguel Almeida, created a podcast, called Podcast EESPS. </p>
        <p>There, they talked about many topics around the environment, related to themes, such as energy, waste and pollution.</p>
        <a href="https://eesps.weebly.com/podcast.html">Check it out!</a>
    </div>
     <div>
      <h2>Podcast "Janela de Oportunidade"</h2>
      <p>Our world is facing a climate crisis! Filipe's podcast "Janela de Oportunidade" talks about the problems our world is facing, the history that follows us and the future we can expect.</p>
      <p>Released in the beginning of 2024 and available everywhere where podcasts are heard!</p>
      <p><Link to="/podcast-janela-oportunidade/">More information on his post</Link></p>
     </div>
      </div>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {category: {eq: "environment"}}}
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
